<template>
    <v-card flat class="fill-height box">
        <v-app-bar flat class="row header mx-0">
            <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
            <v-divider class="mx-3" inset vertical></v-divider>
            <span class="headline">Rental Shop</span>

        </v-app-bar>
        <v-card-text class="row content mx-0">
        
            <v-row align-content="center" justify="center">
                <v-col cols="10" md="8" lg="6">

                    <div v-show="view == 'form'">
                        <div class="headline pl-0">Event Status</div>
                        <v-card-subtitle class="pt-0 pl-0">Please enter the event id and your password to continue</v-card-subtitle>

                        <v-form ref="form">
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field v-model="eventNum" outlined placeholder="Event Number" v-mask="'#######'" 
                                        :rules="[ rules.required ]" @keypress.enter="loadEvent"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field v-model="phoneNum" outlined placeholder="Password" type="password" 
                                        :rules="[ rules.required ]" @keypress.enter="loadEvent"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-btn block depressed :x-large="!isMobile" color="grey darken-4" class="white--text" :loading="loading" @click="loadEvent">Find</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>

                    <div v-if="view == 'event' && event != null">
                        <v-btn depressed color="grey darken-4" class="white--text mb-5" @click="gotoForm"><v-icon left>mdi-arrow-left</v-icon> go back</v-btn>
                        <v-subheader>Event details</v-subheader>
                        <v-simple-table>
                            <tbody>
                                <tr>
                                    <td>Event num</td>
                                    <td>{{ event.foreignKey }}</td>
                                </tr>
                                <tr>
                                    <td>Event type</td>
                                    <td>{{ event.eventType }}</td>
                                </tr>
                                <tr>
                                    <td>Event date</td>
                                    <td>{{ event.eventDate }}</td>
                                </tr>
                                <tr>
                                    <td>Location</td>
                                    <td>{{ event.location }}</td>
                                </tr>
                                <tr>
                                    <td>Coordinators</td>
                                    <td>{{ coordinatorNames }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>

                        <v-subheader>Participants</v-subheader>
                        <v-data-table :headers="headers" :items="event.participants">
                            <template v-slot:item.status="{ item }">
                                {{ item.orderId == 0 ? 'Pending' : 'Ordered' }}
                            </template>
                        </v-data-table>
                    </div>

                </v-col>
            </v-row>

            <v-btn absolute bottom left depressed x-large color="error" @click="restart" :icon="$vuetify.breakpoint.smAndDown">
                <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-restart</v-icon> 
                <span v-if="!$vuetify.breakpoint.smAndDown">Restart</span>
            </v-btn>

        </v-card-text>
    </v-card>
</template>

<script>
import { API } from '@/inc/api';
import { mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';

export default {
    data: () => ({
        event       : null,

        eventNum    : '',
        phoneNum    : '',
        view        : 'form',

        loading     : false,
        isMobile    : false,

        rules       : {
            required: v => !!v || 'required',
        },

        headers     : [
            { text: 'Guest', value: 'customer.fullName', width: '60%', sortable: true },
            { text: 'Role', value: 'eventRole', sortable: true },
            { text: 'Status', value: 'status', sortable: true },
        ]
    }),
    computed: {
        ...mapGetters({
            path    : 'path'
        }),
        coordinatorNames() {
            if( this.event.coordinator1Id != 0 && this.event.coordinator2Id != 0 )
                return `${this.event.coordinator1.fullName} & ${this.event.corrdinator2.fullName}`;

            if( this.event.coordinator1Id != 0 )
                return this.event.coordinator1.fullName;

            return this.event.corrdinator2.fullName;
        }
    },
    mounted() {
        if( this.path != 'event-status' )
            this.$router.push('/');

        this.onResize();
    },
    methods: {
        async loadEvent() {
            if( !this.$refs.form.validate() )
                return;

            try {
                let api = new API();

                this.loading = true;
                let res = await api.post('/event-status', {
                    eventNum: this.eventNum,
                    password: this.phoneNum
                });
                this.loading = false;
                this.eventNum = '';
                this.phoneNum = '';

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error === true ? 'Error' : 'Warning', res.data.message, res.data.error === true ? 'error' : 'warning' );
                    return;
                }

                this.event = res.data.data;
                this.view = 'event';
            }
            catch(error) {
                this.mxShowMessage('Error', error, 'error');
                this.loading = false;
                this.phoneNum = '';
            }
            finally {
                this.$refs.form.resetValidation();
            }
        },
        gotoForm() {
            this.view = 'form';
            this.event = null;
        },
        onResize() {
            this.isMobile = window.innerWidth < 600;
        },
    },
    directives: { mask }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.fit {
  background-color: #ececec;
}

.hover:hover {
    background-color: #e7e7e7 !important;
}
</style>